import React from 'react';
import dynamic from 'next/dynamic';

// import { Button } from '@mui/material';
import { Box } from '@mui/system';
import Link from 'next/link';

const CustomButton = dynamic(() => import('@components/Button/Button'), {
  ssr: false,
});
export default function FreeCoinsCard() {
  return (
    <Box
      padding='13px 15px'
      className='bg-white rounded-md dark:bg-header-black h-full w-full dark:border-1 dark:border-custom-border'
      border='1px solid #EBEBEF'
    >
      <div className='pt-1'>
        <Box>
          <div className='font-bold   '>
            Give & Get Free AI Words
            {/* <img
              width='20px'
              alt='ink-points'
              src='/assets/diamond.png'
              className='mx-1'
            /> */}
          </div>
          <div className=' pt-2'>
            <div className='text-sm'>
              Give and get free AI Words by sharing INK, or get free AI words
              for your honest review and more!
            </div>
          </div>
          <div className='pt-3'>
            <Link href='/free-points'>
              <CustomButton className='btn-custom-blue'>
                Learn More
              </CustomButton>
            </Link>
          </div>
        </Box>
      </div>
    </Box>
  );
}
