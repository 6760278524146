import React from 'react';
import { Box } from '@mui/material';
import Image from 'next/image';
import EastIcon from '@mui/icons-material/East';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { useTheme } from 'next-themes';

const ImageContainer = styled('div')(
  (props) => `
  display: flex;
  margin-right: ${props.noMargin ? '0px' : '20px'};
  & .image-dark {
    filter: brightness(0) invert(1);
  }
`
);

export default function DashboardBanner({
  title,
  description,
  onClick,
  btnName,
  bgSrc,
  width,
  height,
  iconSrc,
  id,
}) {
  const { theme } = useTheme();
  return (
    <Box
      id={id}
      onClick={onClick}
      className={` h-full  relative opacity-[0.9] cursor-pointer hover:opacity-[1] rounded-md py-[18px] flex flex-col px-[16px]  text-white`}
      sx={{
        background: `url(${bgSrc})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      }}
    >
      <div className='relative z-10 flex flex-col h-full'>
        <div className=''>
          <div className='font-bold text-[24px]'>{title}</div>
          <div className='pt-2'>{description}</div>
        </div>
        <div className='mt-auto pt-8 flex items-center justify-between'>
          <div className='flex items-center'>
            <span className='pr-3 font-[600]'>{btnName}</span>
            <EastIcon />
          </div>
          {/* <Image
            width={width}
            height={height}
            src={iconSrc}
            alt='ink-document'
          /> */}
          <ImageContainer>
            <Image
              src={iconSrc}
              alt='ink'
              width='40px'
              height='40px'
              className={theme === 'dark' ? 'image-dark' : null}
            />
          </ImageContainer>
        </div>
      </div>
      {/* <Image
        layout='fill'
        className='object-center  object-cover pointer-events-none rounded-md'
        src={bgSrc}
        alt='online'
      /> */}
    </Box>
  );
}
DashboardBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  btnName: PropTypes.string,
  bgSrc: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  iconSrc: PropTypes.string,
  id: PropTypes.string,
};
