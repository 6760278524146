import React, { useEffect } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import moment from 'moment';
import dynamic from 'next/dynamic';
// import Avatar from '@components/Avatar/Avatar';
import { setSelectedAvatarOption, setTeamMembers } from '@redux/actions/user';
import { useDispatch } from 'react-redux';
import { getStats } from '@redux/services/user';
import { IsMobileWidth, IsTabletWidth } from '@components/util/MediaQueryUtils';
import clsx from 'clsx';
import { captureManualEvent, identifyTeam } from '@services/analytics';
import { subscribeMqtt } from '@redux/services/mqtt';
import CustomSkeleton from '@components/Skeleton/Skeleton';
import { setDataInLocalStorage } from '@helpers/browser_storage';
import { getUserTeamDetail } from '@redux/services/teams';

const Avatar = dynamic(() => import('@components/Avatar/Avatar'), {
  ssr: false,
});
export default function ReportCard({
  selectedAvatarOption,
  userAccounts,
  stats,
  authStatus,
  loggedUser,
  dataFetchedFrom,
}) {
  const dispatch = useDispatch();

  const handleAccountChange = (team, event) => {
    if (team?.title === selectedAvatarOption?.title) {
      event.preventDefault();
      return;
    }
    // let action = '';
    const { _id } = team;
    const obj = {
      teamId:
        selectedAvatarOption?.accountType !== 'personal'
          ? selectedAvatarOption?._id
          : null,
      category: 'click',
      trialStart: loggedUser?.trialInfo?.startedOn
        ? moment(loggedUser?.trialInfo?.expiry).unix() > moment().unix()
          ? moment(loggedUser?.trialInfo?.startedOn).unix()
          : null
        : null,
      trialEnd: loggedUser?.trialInfo?.expiry
        ? moment(loggedUser?.trialInfo?.expiry).unix() > moment().unix()
          ? moment(loggedUser?.trialInfo?.expiry).unix()
          : null
        : null,
      label: selectedAvatarOption?.title
        ? selectedAvatarOption?.title
        : selectedAvatarOption?.accountType,
      // action,
    };
    if (['personal'].includes(team.accountType)) {
      // action = 'select-personal';
      // subscribeMqtt(`userUpdate_${_id}`);
      identifyTeam(null);
    } else {
      // action = 'select-team';
      // subscribeMqtt(`inkTeam_${_id}`);
      identifyTeam(_id);
    }
    captureManualEvent(obj);
    dispatch(setSelectedAvatarOption(team));
    if (team?.accountType !== 'personal') {
      getUserTeamDetail(team?._id).then((res) => {
        const {
          data: { data },
        } = res;
        const members = [
          ...data?.members?.map((member) => ({
            id: member?.user?._id,
            name: member?.user?.name,
            mine: loggedUser?.loggedUser?._id === member?.user?._id,
          })),
          {
            id: data?.owner?._id,
            name: data?.owner?.name,
            mine: loggedUser?.loggedUser?._id === data?.owner?._id,
          },
        ];
        dispatch(setTeamMembers(members));
      });
    }
    setDataInLocalStorage('avatarSelectedData', team);
  };
  useEffect(() => {
    if (authStatus === 'authenticated' && dataFetchedFrom === 'API') {
      if (selectedAvatarOption?.accountType === 'personal') {
        dispatch(getStats({ userId: selectedAvatarOption?._id }));
      } else {
        dispatch(getStats({ teamId: selectedAvatarOption?._id }));
      }
    }
  }, [selectedAvatarOption, dataFetchedFrom, authStatus, dispatch]);
  const mobileWidth = IsMobileWidth();
  const tabletWidth = IsTabletWidth();
  return (
    <div>
      <Box
        className='bg-white rounded-md dark:bg-header-black dark:border-1 dark:border-custom-border'
        padding='11px 15px'
        border='1px solid #EBEBEF'
      >
        <div
          className={clsx(
            !(mobileWidth || tabletWidth) && 'flex items-start justify-between',
            (mobileWidth || tabletWidth) && ''
          )}
        >
          <div>
            <div
              // variant='body1'
              className='text-custom-pink font-bold capitalize'
            >
              {selectedAvatarOption?.title ?? (
                <CustomSkeleton variant='text' height={24} width={150} />
              )}
            </div>
            <div className='font-bold pt-1' style={{ fontSize: '32px' }}>
              {stats?.duration
                ? moment(stats?.duration).format('MMMM YYYY')
                : moment().format('MMMM YYYY')}
            </div>
            <div className='flex items-center pt-3'>
              {userAccounts?.length ? (
                userAccounts.map((team, index) => (
                  <>
                    <Avatar
                      picture={team?.picture}
                      title={team?.title}
                      handleClick={(event) => handleAccountChange(team, event)}
                      active={team?.title === selectedAvatarOption?.title}
                      alt='user'
                    />
                    {userAccounts.length !== index + 1 ? (
                      <span className='p-2'>
                        <Image
                          alt='switch'
                          src='/assets/wire.png'
                          width={16}
                          height={12}
                        />
                      </span>
                    ) : null}
                  </>
                ))
              ) : (
                <CustomSkeleton variant='circular' width={40} height={40} />
              )}
            </div>
          </div>
          <div
            className={clsx(
              !(mobileWidth || tabletWidth) && 'pr-4',
              (mobileWidth || tabletWidth) && 'pt-7'
            )}
          >
            <div>
              <div className='font-bold'>Performance Report</div>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap'
                className='pt-3'
              >
                <div className='text-sm'>Words generated</div>
                <div className='font-bold text-sm pl-4 w-16 flex justify-end'>
                  {stats?.wordGenerated ?? (
                    <CustomSkeleton variant='text' width={20} height={20} />
                  )}
                </div>
              </Box>
              <Box
                className='pt-2'
                display='flex'
                flexWrap='wrap'
                justifyContent='space-between'
              >
                <div className='text-sm'>AI written</div>
                <div className='font-bold text-sm pl-4 w-16 flex justify-end'>
                  {stats?.rewritesUsed ?? (
                    <CustomSkeleton variant='text' width={20} height={20} />
                  )}
                </div>
              </Box>
              <Box
                display='flex'
                className='pt-2'
                flexWrap='wrap'
                justifyContent='space-between'
              >
                <div className='text-sm'>AI rewritten</div>
                <div className='font-bold text-sm pl-4 w-16 flex justify-end'>
                  {stats?.inkPointsUsed ?? (
                    <CustomSkeleton variant='text' width={20} height={20} />
                  )}
                </div>
              </Box>
              <Box
                display='flex'
                className='pt-2'
                flexWrap='wrap'
                justifyContent='space-between'
              >
                <div className='text-sm'>SEO keywords</div>
                <div className='font-bold text-sm pl-4 w-16 flex justify-end'>
                  {stats?.seoKeywords ?? (
                    <CustomSkeleton variant='text' width={20} height={20} />
                  )}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

ReportCard.propTypes = {
  selectedAvatarOption: PropTypes.object,
  userAccounts: PropTypes.array,
  stats: PropTypes.object,
  loggedUser: PropTypes.object,
  authStatus: PropTypes.string,
  dataFetchedFrom: PropTypes.string,
};
