import React from 'react';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { IsMobileWidth } from '@components/util/MediaQueryUtils';
import Image from 'next/image';
import CustomButton from '@components/Button/Button';
import { useTheme } from 'next-themes';
import Link from 'next/link';
import { styled } from '@mui/material/styles';

const CardWrapper = styled('div')`
.card-link{
 hover:border hover:border-custom-border hover:bg-[#F6F7F8]
}
.card-link:hover{
 background-color:${(props) =>
   props.theme === 'dark' ? '#31313F' : '#F6F7F8'} ;
 border:1px solid ${(props) =>
   props.theme === 'dark' ? '#F5F5F5' : '#505876'}   ;
}
`;
export default function CardLabel(props) {
  const { src, title, description, link } = props;
  // const mobileWidth = IsMobileWidth();
  const { theme } = useTheme();

  return (
    <CardWrapper theme={theme}>
      <Link href={link}>
        <a>
          <Box
            className='card-link mb-[10px] bg-white cursor-pointer  rounded-md dark:bg-header-black h-full w-full dark:border-1 dark:border-custom-border'
            padding='15px 15px'
            border='1px solid #EBEBEF'
          >
            <div className='flex'>
              <img src={src} alt='doc' className='w-[32px] h-[32px]' />
              <div className='pl-4'>
                <div className='font-bold'>{title}</div>
                <p className='pt-2 text-sm'>{description}</p>
              </div>
            </div>
          </Box>
        </a>
      </Link>
    </CardWrapper>
  );
}
CardLabel.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  // size: PropTypes.string,
  link: PropTypes.string,
};
