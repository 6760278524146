import React from 'react';
import dynamic from 'next/dynamic';
import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

const CustomButton = dynamic(() => import('@components/Button/Button'), {
  ssr: false,
});
export default function PromotionalCard({
  title = 'mock',
  subtitle,
  link,
  buttonText = 'mock',
  target = false,
}) {
  const { theme } = useTheme();
  const router = useRouter();
  // const CardWrapper = styled('div')(() => ({
  //   border: theme === 'dark' ? '2px solid #1E2628' : '1px solid #EBEBEF',
  //   padding: '10px 15px',
  //   backgroundImage: 'url(/assets/bed.card.png)',
  //   borderRadius: '7px',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover',
  // }));

  const handleNavigation = () => {
    if (target) return window.open(link);
    router.push(link);
  };
  return (
    <Box
      padding='18px 15px'
      className=' bg-white dark:bg-header-black dark:text-white  relative  dark:border-2 dark:border-[#1E2628] '
      // marginTop='10px'
      border='1px solid #EBEBEF'
      borderRadius='0.375rem'
    >
      {/* <Image
          layout='fill'
          className=' object-cover object-left-top pointer-events-none rounded-md'
          src='/assets/bed.card.png'
          alt='online'
        /> */}
      <div className='relative z-1'>
        <div className='font-bold '>{title}</div>
        <div className='pt-2'>
          <div className='text-sm'>{subtitle}</div>
        </div>
        <div className='mt-4'>
          <CustomButton
            style={{
              padding: '7px 14px',
              backgroundColor: '#E45C96',
              color: 'white',
            }}
            id='button-navigate-plans'
            onClick={handleNavigation}
          >
            {buttonText}
          </CustomButton>
        </div>
      </div>
    </Box>
  );
}

PromotionalCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  target: PropTypes.bool,
};
