import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { capturingPostHogEvent } from '@helpers/utils';
import LoadingState from '@components/Loader/LoadingState';
import PropTypes from 'prop-types';
import { isOnAgencyTrialPlan } from '@components/settings/_helper';

const CustomButton = dynamic(() => import('@components/Button/Button'), {
  ssr: false,
});

const UpgradeButton = ({ wrapperClasses = '', className = '' }) => {
  const router = useRouter();

  const { user } = useSelector((state) => state);

  const handleNavigation = (url) => {
    capturingPostHogEvent(`Button: Naviagte to ${url}`);
    router.push(url);
  };

  return (
    <>
      {user?.selectedAvatarOption?.userPlan?.name === 'INK FREE' ||
      user?.selectedAvatarOption?.userPlan?.name === 'Free' ? (
        <CustomButton
          wrapperClasses={wrapperClasses}
          id='button-navigate-plans'
          onClick={() => handleNavigation('/plans')}
          btnColor='outlined-pink'
          style={{ padding: '9px' }}
          className={className}
        >
          Upgrade Now
        </CustomButton>
      ) : (
        <CustomButton
          wrapperClasses={wrapperClasses}
          id='button-navigate-account'
          btnColor={
            isOnAgencyTrialPlan(user?.loggedUser)
              ? 'outlined-pink'
              : 'outlined-blue'
          }
          onClick={() => handleNavigation('/account')}
          className={className}
        >
          {user?.selectedAvatarOption?.userPlan?.name ? (
            isOnAgencyTrialPlan(user?.loggedUser) ? (
              'Agency Trial' //
            ) : (
              user?.selectedAvatarOption?.userPlan?.name
            )
          ) : (
            <LoadingState />
          )}
        </CustomButton>
      )}
    </>
  );
};

UpgradeButton.propTypes = {
  wrapperClasses: PropTypes.string,
  className: PropTypes.string,
};

export default UpgradeButton;
