import React from 'react';
import dynamic from 'next/dynamic';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import { Box } from '@mui/system';
import Image from 'next/image';

const CustomButton = dynamic(() => import('@components/Button/Button'), {
  ssr: false,
});
export default function ReportCard() {
  const { theme } = useTheme();
  const router = useRouter();
  // const CardWrapper = styled('div')(() => ({
  //   border: theme === 'dark' ? '2px solid #1E2628' : '1px solid #EBEBEF',
  //   padding: '10px 15px',
  //   backgroundImage: 'url(/assets/bed.card.png)',
  //   borderRadius: '7px',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover',
  // }));

  const handleNavigation = () => {
    router.push('/plans');
  };
  return (
    <div>
      <Box
        padding='18px 15px'
        className=' bg-white dark:bg-header-black dark:text-white  relative  dark:border-2 dark:border-[#1E2628] '
        // marginTop='10px'
        border='1px solid #EBEBEF'
        borderRadius='16px'
      >
        {/* <Image
          layout='fill'
          className=' object-cover object-left-top pointer-events-none rounded-md'
          src='/assets/bed.card.png'
          alt='online'
        /> */}
        <div className='pt-2 pb-2 relative z-1'>
          <div className='font-bold '>Upgrade Today</div>
          <div className='pt-2'>
            <div className='text-sm'>
              Don’t wait - you could be creating amazing content 10X faster and
              increase your content performance 5X.
            </div>
          </div>
          <div className='mt-5'>
            <CustomButton
              id='button-navigate-plans'
              btnColor='outlined-pink'
              // style={{ padding: '11px 30px' }}
              onClick={handleNavigation}
            >
              Compare Plans
            </CustomButton>
          </div>
        </div>
      </Box>
    </div>
  );
}
