import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function ShadowBox({ children, padding, sx, noUpperStyles, ...props }) {
  return (
    <Box
      padding={padding}
      className='bg-white rounded-md dark:bg-header-black  w-full  dark:border-1 dark:border-custom-border'
      border='1px solid #EBEBEF'
      sx={{
        borderRadius: noUpperStyles ? '0px 0px 10px 10px !important' : '10px ',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export default ShadowBox;

ShadowBox.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
  padding: PropTypes.string,
  noUpperStyles: PropTypes.bool,
};
