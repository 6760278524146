import React from 'react';
import { useTheme } from 'next-themes';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import { IsMobileWidth } from '@components/util/MediaQueryUtils';

export default function Card(props) {
  const {
    src,
    title,
    description,
    imgWidth = '32px',
    imgHeight = '32px',
    icon,
    boxHeight,
    handleClick,
  } = props;
  // const mobileWidth = IsMobileWidth();
  const { theme } = useTheme();

  return (
    <Box
      className='rounded-md  w-full dark:border-1 dark:border-custom-border'
      border='1px solid #EBEBEF'
      height={boxHeight}
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        background: theme === 'dark' ? '#252530' : '#ffffff',
      }}
    >
      <Box
        padding='15px 15px'
        height='100%'
        sx={{
          '&:hover': {
            background: 'rgba(25, 118, 210, 0.04)!important',
            transition: '250ms cubic-bezier(0.4, 0, 0.2, 1)',
          },
        }}
      >
        <div className='flex items-start justify-between flex-wrap md:flex-nowrap'>
          <div>
            <img width={imgWidth} height={imgHeight} src={src} alt='doc' />
          </div>
          <div>{icon}</div>
        </div>
        <div className='pt-3'>
          <div className='font-bold'>{title}</div>
          <p
            className='pt-2'
            /* Overwrite styles coming from editor. (Open editor and go back to documents page) */
            style={{
              fontFamily: `'Montserrat',sans-serif`,
              fontSize: '1rem',
              fontWeight: '400',
              lineHeight: '1.5',
            }}
          >
            {description}
          </p>
        </div>
      </Box>
    </Box>
  );
}
Card.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node,
  imgWidth: PropTypes.string,
  imgHeight: PropTypes.string,
  boxHeight: PropTypes.string,
  handleClick: PropTypes.func,
  // size: PropTypes.string,
};
