import { useRouter } from 'next/router';
import { Grid } from '@mui/material';
import { PATH_EDITOR } from '@editor/config';
import Card from '@library/components/Molecules/Cards/Card';

const workflows = [
  {
    title: 'Blog Post',
    desc: 'Create a blog post with an outline, and multiple paragraphs.',
    link: `${PATH_EDITOR}?wfl=001`,
  },
  {
    title: 'Social Media Campaign',
    desc: 'Create an entire social media campaign designed to increase your online reach.',
    link: `${PATH_EDITOR}?wfl=002`,
  },
  {
    title: 'Email Campaign',
    desc: 'Create cold email sequences that have high open & click rates.',
    link: `${PATH_EDITOR}?wfl=003`,
  },
  {
    title: 'Ad Campaign',
    desc: 'Brainstorm an entire ad campaign for social media and Google.',
    link: `${PATH_EDITOR}?wfl=004`,
  },
];

function Popular() {
  const router = useRouter();

  return (
    <Grid container spacing={2}>
      {workflows.map((item, idx) => (
        <Grid key={idx} xs={12} md={3} lg={3} item>
          <Card
            src='/assets/dashboard/doc.svg'
            icon={<img src='/assets/dashboard/flash.svg' alt='flash' />}
            title={item.title}
            description={item.desc}
            boxHeight='100%'
            handleClick={() => {
              router.push(item?.link);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default Popular;
