import moment from 'moment';

export function isOnAgencyTrialPlan(loggedUser) {
  return (
    loggedUser?.trialInfo?.isTrialActivated === true &&
    loggedUser?.userPlan?.name === 'Agency - [TRIAL]'
  );
}

export function getTrialRemainingDays(loggedUser) {
  const expiryDate = loggedUser?.trialInfo?.expiry;
  const today = moment();
  const expiry = moment(expiryDate);
  const diff = expiry.diff(today, 'days');
  return diff;
}

export const FEATUTRES_NAME_MAP = {
  keywords: 'INK Credits',
  words: 'AI Words',
  images: 'AI Images',
  articles: 'SEO Articles',
};
