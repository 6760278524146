import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'next/image';
import Main from '@components/layouts/main/main';
import ReportCard from '@components/report.card/report.card';
import UpgradeCard from '@components/upgrade.card/upgrade.card';
import ShareInkCard from 'components/share.ink.card/share.ink.card';
import JoinCard from '@components/join.card/join.card';
import FreeCoinsCard from '@components/free.coins.card/free.coins.card';
import Route from '@components/Route/Route';
import { useTheme } from 'next-themes';
// import CustomButton from '@components/Button/Button';
import { useRouter } from 'next/router';
import LoadingState from '@components/Loader/LoadingState';
import dynamic from 'next/dynamic';
import { IsMobileWidth, IsTabletWidth } from '@components/util/MediaQueryUtils';
import EastIcon from '@mui/icons-material/East';
import DashboardBanner from '@components/dashboard.banner/dashboard.banner';
import { capturingPostHogEvent, isInPWA } from '@helpers/utils';
import SocialLinkCard from '@components/SocialLinkCard/SocialLinkCard';
import PromotionalCard from '@components/PromotionalCard/PromotionalCard';
import UpgradeButton from '@components/upgrade.button';
import CustomButton from '@components/Button/Button';
import { styled } from '@mui/material/styles';
import TutorialModal from '@components/Modal/TuturialModal';
import { useEffect, useMemo, useState } from 'react';
import useFirstTimeVisitChecker from 'hooks/useFirstTimeVisitChecker';
import FeatureTutorial from '@components/FeatureTutorial/FeatureTutorial';
import WatchTutorialButton from '@components/Button/WatchTutorial';
import Card from '@library/components/Molecules/Cards/Card';
import CardLabel from '@library/components/Molecules/Cards/CardLabel';
import Link from 'next/link';
import GradientText from '@library/components/Atoms/UI/GradientText';
import { SectionTitle } from '@library/components/Atoms';
import PopularWorkflow from '@components/workFlow/Popular';
import { PATH_ALL_DOCUMENTS, PATH_EDITOR } from '@editor/config';
import useCommon from 'hooks/useCommon';

const Roadmap = [
  {
    title: 'Plan',
    steps: [
      {
        image: '/assets/dashboard/keyword-research.svg',
        heading: 'Keyword Research',
        desc: 'The fastest way to find relevant keywords where you can win.',
        link: '/content-planning/keyword-research',
      },
      {
        image: '/assets/dashboard/keyword-clustering.svg',
        heading: 'Keyword Clustering',
        desc: 'Turn your keyword list into a content plan with fresh Google data.',
        link: '/content-planning/keyword-clustering',
      },
    ],
  },
  {
    title: 'Create',
    steps: [
      {
        image: '/assets/dashboard/write.svg',
        heading: 'Write',
        desc: 'Write long copy with ease, amplify your content creation.',
        link: PATH_ALL_DOCUMENTS,
      },
      {
        image: '/assets/dashboard/assistant.svg',
        heading: 'Assistant',
        desc: 'Write short copy with a simple request, trained on 130+ marketing scenarios.',
        link: '/ai-assistant',
      },
      {
        image: '/assets/dashboard/image.svg',
        heading: 'Images',
        desc: 'Create images that you are exactly what you want to see.',
        link: '/image-generator',
      },
    ],
  },
  {
    title: 'Win',
    steps: [
      {
        image: '/assets/dashboard/seo-optimize.svg',
        heading: 'SEO Optimize',
        desc: 'Optimize content to be 450% more likely to rank page 1 in search with semantic SEO.',
        link: PATH_ALL_DOCUMENTS,
      },
      {
        image: '/assets/dashboard/secure.svg',
        heading: 'Secure',
        desc: 'Protect your content and site from plagiarism and content that sounds like AI.',
        link: '/ai-shield/content-audit',
      },
    ],
  },
];

export default function Home() {
  const {
    user,
    general: { dataFetchedFrom },
  } = useSelector((state) => state);
  const { ACCESS_CHECKS } = useCommon();
  const { loggedUser, team } = user;
  const router = useRouter();
  const handleNavigation = (url) => {
    capturingPostHogEvent(`Button: Naviagte to ${url}`);
    router.push(url);
  };
  const mobileWidth = IsMobileWidth();
  const istabletWidth = IsTabletWidth();
  const [tutorialOpen, setTutorialOpen] = useState(false);

  const promotionalCardProps = () => {
    const allCardContent = [
      {
        includes: [
          ACCESS_CHECKS.isProfessional,
          ACCESS_CHECKS.isCustom,
          ACCESS_CHECKS.isTeamMember,
          ACCESS_CHECKS.isAgency,
        ],
        content: {
          title: 'INK Bootcamp',
          subtitle: (
            <p>Easily master content creation and optimization with INK AI.</p>
          ),
          buttonText: 'Learn More',
          link: 'https://help.inkforall.com/ink-bootcamp',
          target: true,
        },
      },

      {
        includes: [ACCESS_CHECKS.isFree],
        content: {
          title: 'Try INK For Free',
          subtitle: (
            <p>
              See for yourself why everyone loves INK. Our risk free trial will
              unlock all the INK features for 5 days. No credit card or purchase
              required.
            </p>
          ),
          buttonText: 'Start Trial',
          link: '/plans',
        },
      },

      {
        includes: [ACCESS_CHECKS.isEnterprise],
        content: {
          title: 'Build Your Team',
          subtitle: (
            <p>
              Create your team, invite team members, and start collaborating.
              INK makes it easy to manage your team. Try it now!
            </p>
          ),
          buttonText: 'Create Team',
          link: '/team',
        },
      },

      {
        includes: [ACCESS_CHECKS.onTrail],
        content: {
          title: 'How Can We Help You?',
          subtitle: (
            <p>
              If you have any questions about your trial, our support team is
              here to help you.
              <span
                className='underline mx-1 cursor-pointer'
                onClick={() => window.HubSpotConversations.widget.open()}
              >
                Chat with our team today
              </span>
              or
              <a
                href='https://meetings.hubspot.com/brooke-love'
                className='underline ml-1'
                target='_blank'
                rel='noreferrer'
              >
                book a private training session.
              </a>
            </p>
          ),
          buttonText: 'Upgrade',
          link: '/plans',
        },
      },
    ];

    if (!loggedUser) return;

    const cardContent = allCardContent.find((card) =>
      card.includes.some((include) => include)
    );

    return cardContent?.content;
  };

  const Description = () => {};

  const watchTutorial = () => {
    setTutorialOpen(true);
    // capturingPostHogEvent('Button: Watching a Tutorial');
  };

  const firstTimeVisit = useFirstTimeVisitChecker('dashboard');

  useEffect(() => {
    if (firstTimeVisit) {
      setTutorialOpen(true);
    }
  }, [firstTimeVisit]);
  const { theme } = useTheme();

  return (
    <Main>
      <Route>
        {tutorialOpen ? (
          <FeatureTutorial
            featureName='dashboard'
            withinPage
            handleClose={() => setTutorialOpen(false)}
          />
        ) : (
          <>
            <div className='flex flex-wrap justify-between items-center pb-2'>
              <GradientText
                variant='h1'
                fontWeight='700'
                fontSize={istabletWidth || mobileWidth ? '20px' : '32px'}
              >
                Dashboard
              </GradientText>
              <div className='flex items-stretch'>
                {istabletWidth || mobileWidth ? (
                  <Box
                    className={`w-11 h-11 m-auto border ${
                      theme === 'dark' ? 'border-[#31313f]' : 'border-[#EBEBEF]'
                    } rounded-md flex justify-center items-center ${
                      theme === 'dark' ? 'bg-[#252530]' : 'bg-white'
                    } mr-4 lg:mr-0`}
                    onClick={watchTutorial}
                  >
                    <img
                      src={
                        theme === 'dark'
                          ? '/assets/video-dark.svg'
                          : '/assets/video.svg'
                      }
                      alt='video'
                    />
                  </Box>
                ) : (
                  <WatchTutorialButton
                    className='h-[41px]'
                    watchTutorial={watchTutorial}
                  />
                )}
                <UpgradeButton
                  className={`${mobileWidth ? '!py-[7px]' : null}`}
                />
              </div>
            </div>
            <Box
              className='dark:text-white rounded p-4 lg:p-0 lg:px-8 block md:flex items-center justify-between relative bg-[#EDEEF1] dark:bg-[#313140]'
              marginTop='10px'
              marginBottom='16px'
            >
              <div className='relative z-1'>
                <div className='text-2xl font-weight-custom'>
                  INK SEO Writer
                </div>
                <p className='pt-1 max-w-[400px]'>
                  Revolutionizing Content Creation: Start writing content that
                  gets found, engages and converts
                </p>
              </div>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'block',
                  },
                }}
              >
                <img
                  className='h-36'
                  src={
                    theme === 'dark'
                      ? '/assets/dashboard/document.dark.png'
                      : '/assets/dashboard/document.png'
                  }
                  alt='online'
                />
              </Box>
              <CustomButton
                className='mr-[30px] mt-6 md:mt-0 w-full sm:w-auto whitespace-nowrap'
                btnColor='blue'
                href={PATH_EDITOR}
                target={isInPWA() ? '_self' : '_blank'}
              >
                <img
                  className='mr-[10px]'
                  alt='document'
                  src='/assets/dashboard/document.svg'
                />
                Start Writing
              </CustomButton>
            </Box>

            <Grid container spacing={2} alignItems='start'>
              {Roadmap.map((item, rIdx) => (
                <Grid key={rIdx} xs={12} md={4} lg={4} item>
                  <Box
                    border='1px solid #EBEBEF'
                    className='bg-[#FFFFFF] rounded-md p-4 dark:bg-[#252530] dark:border-[#31313F]'
                    marginBottom='16px'
                  >
                    <div className='font-bold mb-4'>{item.title}</div>
                    {item.steps.map((elem, sIdx) => (
                      <CardLabel
                        key={sIdx}
                        src={elem.image}
                        title={elem.heading}
                        description={elem.desc}
                        link={elem.link}
                      />
                    ))}
                  </Box>
                </Grid>
              ))}
            </Grid>

            {/* Workflow Cards */}
            {loggedUser?.documentMigration === 'done' && (
              <>
                <SectionTitle title='Popular Workflows' />
                <PopularWorkflow />
              </>
            )}

            <Grid container spacing={2} alignItems='start' className='pt-4'>
              <Grid xs={12} md={6} lg={6} item>
                <SocialLinkCard />
                <PromotionalCard {...promotionalCardProps()} />
              </Grid>
              {/*
              // ! DISABLED referal program because we want to reuse seo.app domain
              */}
              {/* <Grid xs={12} md={6} lg={6} item>
                <ShareInkCard
                  description={Description()}
                  referralCode={user?.loggedUser?.referralCode}
                  image
                />
              </Grid> */}
            </Grid>
          </>
        )}
      </Route>
    </Main>
  );
}
