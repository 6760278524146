import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomButton from '@components/Button/Button';
import ShadowBox from '@library/components/Atoms/UI/ShadowBox';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import GradientText from '@library/components/Atoms/UI/GradientText';
//* ALL TUTORIALS ARE STORED IN THIS JSON FILE  data:(iframeSrc, title, returnTo)
import tutorials from './tutorials.json';

const FeatureTutorial = ({
  withinPage,
  featureName,
  close,
  handleClose,
  extLinkTarget = '_blank',
}) => {
  const { theme } = useTheme();
  const router = useRouter();
  const [matchedFeature, setMatchedFeature] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  console.log('featureName', tutorials);

  useEffect(() => {
    const match = tutorials[featureName];
    setMatchedFeature(match);
    setIsMounted(true);
  }, [router, featureName]);

  return (
    <>
      {isMounted && matchedFeature && (
        <>
          <GradientText variant='h1' fontWeight='700' fontSize='32px'>
            {matchedFeature?.title}
          </GradientText>

          <ShadowBox
            marginTop='25px'
            padding='60px 16px 40px 16px'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            {withinPage && (
              <Box position='absolute' right='10px' top='10px'>
                <IconButton
                  onClick={() => {
                    if (handleClose) {
                      handleClose();
                    } else {
                      router
                        .push(matchedFeature?.returnTo)
                        .then(() => window.scrollTo(0, 0));
                    }
                  }}
                >
                  <CloseIcon
                    sx={{ color: theme === 'dark' ? '#fff' : '#505876' }}
                  />
                </IconButton>
              </Box>
            )}

            <Stack
              gap='24px'
              sx={{
                maxWidth: '1100px',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                },
              }}
            >
              <Box
                overflow='hidden'
                borderRadius='5px'
                sx={{
                  boxShadow:
                    '0px 0px 20px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div
                  className='wistia_responsive_padding'
                  style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                >
                  <div
                    className='wistia_responsive_wrapper'
                    style={{
                      height: '100%',
                      left: 0,
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                    }}
                  >
                    <iframe
                      src={matchedFeature?.iframeSrc}
                      title={matchedFeature?.title}
                      allow='autoplay; fullscreen'
                      allowtransparency='true'
                      frameBorder='0'
                      scrolling='no'
                      className='wistia_embed'
                      name='wistia_embed'
                      msallowfullscreen
                      width='100%'
                      height='100%'
                    />
                  </div>
                </div>
                <script
                  src='https://fast.wistia.net/assets/external/E-v1.js'
                  async
                />
              </Box>

              <Stack
                sx={{
                  flexDirection: {
                    xs: 'column',
                    md: 'row',
                  },
                  margin: {
                    xs: '0px',
                    sm: '0px 0px 0px 0px',
                    md: '0px 10px 0px 10px',
                    lg: '0px 20px 0px 20px',
                  },
                  gap: {
                    xs: '16px',
                    sm: '16px',
                    md: '40px',
                  },
                }}
              >
                <CustomButton
                  className='btn-custom-pink'
                  size='large'
                  // set flex to 1 for all buttons to be the same width and text whitepace no wrap
                  wrapperClasses='flex-1 whitespace-nowrap'
                  style={{ width: '100%' }}
                  href='https://help.inkforall.com/ink-bootcamp'
                  target={extLinkTarget}
                >
                  Visit INK Bootcamp
                </CustomButton>

                {/*
                 // ! DEPRECATED: will remove it
                */}
                {/* <CustomButton
                  className='btn-custom-purple'
                  size='large'
                  // set flex to 1 for all buttons to be the same width
                  wrapperClasses='flex-1  whitespace-nowrap'
                  style={{ width: '100%', gap: '8px' }}
                  href='https://discord.gg/bpAsSZbpFM'
                  target={extLinkTarget}
                >
                  <img
                    src='/assets/dashboard/discord-outline.svg'
                    alt='Discord'
                  />
                  Join Discord
                </CustomButton> */}

                <CustomButton
                  className='btn-custom-outlined-pink'
                  size='large'
                  // set flex to 1 for all buttons to be the same width
                  wrapperClasses='flex-1  whitespace-nowrap'
                  style={{ width: '100%' }}
                  onClick={() => {
                    if (withinPage && handleClose) {
                      handleClose();
                    } else {
                      router
                        .push(matchedFeature?.returnTo)
                        .then(() => window.scrollTo(0, 0));
                    }
                  }}
                >
                  {close
                    ? 'Close'
                    : withinPage
                    ? 'Skip Tutorial'
                    : `Go to ${matchedFeature?.title}`}
                </CustomButton>
              </Stack>
            </Stack>
          </ShadowBox>
        </>
      )}

      {isMounted && !matchedFeature && (
        <ShadowBox
          marginTop='25px'
          padding='60px 16px 40px 16px'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Typography variant='h4' fontWeight='bold'>
            Tutorial not found
          </Typography>
        </ShadowBox>
      )}

      {!isMounted && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default FeatureTutorial;

FeatureTutorial.propTypes = {
  withinPage: PropTypes.bool,
  close: PropTypes.bool,
  featureName: PropTypes.string,
  handleClose: PropTypes.func,
  extLinkTarget: PropTypes.oneOf(['_self', '_blank', '_new']),
};
