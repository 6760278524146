import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { hasTeam } from '@helpers/utils';

const useCommon = () => {
  const { user } = useSelector((state) => state);
  const { loggedUser, team } = user;
  const ACCESS_CHECKS = useMemo(
    () => ({
      initiatedFreeTrial:
        Object.keys(loggedUser?.trialPlanQuota || {}).length > 0,
      onTrail: loggedUser?.trialPlanQuota?.expiry
        ? new Date(loggedUser?.trialPlanQuota?.expiry).getTime() > Date.now()
        : false,
      isPaidUser: loggedUser?.isPaidUser,
      isTeamMember: hasTeam(),
      isProfessional: loggedUser?.userPlan.name === 'Professional',
      isEnterprise: loggedUser?.userPlan.name === 'Enterprise',
      isFree:
        loggedUser?.userPlan.name === 'INK FREE' ||
        loggedUser?.userPlan.name === 'FREE',
      isCustom: loggedUser?.userPlan.isCustomPlan,
      isAgency: loggedUser?.userPlan?.name?.includes('Agency'),
    }),
    [loggedUser, team]
  );
  return {
    ACCESS_CHECKS,
  };
};

export default useCommon;
