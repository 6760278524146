import React from 'react';
import dynamic from 'next/dynamic';

import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useTheme } from 'next-themes';
import Button from '@components/Button/Button';
import Image from 'next/image';

const ShareWrapper = styled('div')`
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  .custom-btn {
    background-color: #2499e7;
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    padding: 12px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    min-width: 0px;
  }
  .custom-btn:hover {
    background-color: #208ad0;
    color: white;
  }
`;

export default function SocialLinkCard() {
  const buttonOnClick = (link) => {
    window.open(link, '_blank');
  };
  return (
    <ShareWrapper>
      <Box
        padding='12px 15px'
        className='bg-white rounded-md dark:bg-header-black h-full w-full dark:border-1 dark:border-custom-border md:mt-0 mt-4'
        border='1px solid #EBEBEF'
        mt={{ lg: 0, md: 2 }}
      >
        <div className='h-full'>
          <Box display='flex' sx={{ height: '100%' }} flexDirection='column'>
            <div className='font-bold'>What’s new</div>
            <div className='py-3'>
              <div className='text-sm'>
                Get inspired by experts in generative AI and content performance
                to grow your business.
              </div>
            </div>
            <div className='flex flex-col sm:flex-row'>
              <div
                style={{
                  flexGrow: 1,
                  flexBasis: 0,
                }}
              >
                <Button
                  // href='https://www.facebook.com/groups/231841718402866'
                  onClick={() =>
                    buttonOnClick(
                      'https://www.facebook.com/groups/231841718402866'
                    )
                  }
                  className='flex btn-custom-pink !py-[7px] !px-[12px] w-full !mr-[5px]'
                >
                  <Image
                    src='/assets/dashboard/facebook-outline.svg'
                    alt='ink'
                    width='24px'
                    height='24px'
                  />
                  <div className='ml-[10px] text-[16px]'>Join Facebook</div>
                </Button>
              </div>
              {/*
                 // ! DEPRECATED: will remove it
                */}
              {/* <div
                style={{
                  flexGrow: 1,
                  flexBasis: 0,
                }}
                className='sm:mt-0 mt-4'
              >
                <Button
                  onClick={() => buttonOnClick('https://discord.gg/bpAsSZbpFM')}
                  className='flex btn-custom-purple !py-[7px] !px-[12px] w-full !ml-0 sm:!ml-[5px]'
                >
                  <Image
                    src='/assets/dashboard/discord-outline.svg'
                    alt='ink'
                    width='24px'
                    height='24px'
                  />
                  <div className='ml-[10px] text-[16px]'>Join Discord</div>
                </Button>
              </div> */}
            </div>
          </Box>
        </div>
      </Box>
    </ShareWrapper>
  );
}

SocialLinkCard.propTypes = {};
