import React, { useState, useEffect } from 'react';
import { Box, Modal } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from 'next-themes';
import { makeStyles } from '@mui/styles';
import { margin } from '@mui/system';
import PropTypes from 'prop-types';

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 732,
  height: 412,
  bgcolor: '#D5D6DE',
  border: 'none',
  boxShadow: 24,
  position: 'relative',
  zIndex: 10,
};

export default function TutorialModal({ open, setOpen, videoUrl }) {
  const [modalActive, setModalActive] = useState(false);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setModalActive(true);
      }, 200);
    } else {
      setModalActive(false);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setPlay(false);
  };

  const { theme } = useTheme();
  const useStyles = makeStyles({});
  const classes = useStyles();
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ClearIcon
            onClick={handleClose}
            style={{
              cursor: 'pointer',
              zIndex: 20,
              position: 'absolute',
              left: '95%',
              margin: '7px',
            }}
          />
          <iframe
            src={videoUrl}
            title='Introducing the Content Planner Video'
            allow='autoplay; fullscreen'
            allowTransparency='true'
            frameBorder={0}
            scrolling='no'
            name='wistia_embed'
            width='100%'
            height='100%'
          />
        </Box>
      </Modal>
    </div>
  );
}

TutorialModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  videoUrl: PropTypes.string,
};
