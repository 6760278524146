import React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from 'next-themes';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CustomButton from './Button';

const WatchButton = styled(CustomButton)`
  border: ${(props) =>
    props.theme === 'dark' ? ' 1px solid #1E2628' : '1px solid #EBEBEF'};
  font-weight: bold;
  padding: 11px 25px !important;
  margin-right: 10px;
  font-size: 12px;
  background: ${(props) => (props.theme === 'dark' ? '#252530' : '#FFFFFF')};
  border-radius: 5px;
  color: ${(props) => (props.theme === 'dark' ? 'white' : '#505876')};
  width: max-content;
  padding: 8px 15px;
  &:hover {
    color: #505876;
    background: ${(props) =>
      props.theme === 'dark' ? 'rgba(30,38,40,0.2)' : '#ebebef'};
  }
`;

const WatchTutorialButton = ({ watchTutorial, id, className }) => {
  const { theme } = useTheme();
  const useStyles = makeStyles({
    buttonOutlinedSm: {
      border: theme === 'dark' ? ' 1px solid #31313f' : '1px solid #EBEBEF',
      fontWeight: 'bold',
      fontSize: '12px',
      borderRadius: '5px',
      color: theme === 'dark' ? 'white' : ' #505876',
      padding: '8px 15px',
      marginLeft: '16px',
      '&:hover': {
        color: theme === 'dark' ? '#EBEBEF' : '#505876',
        background: theme === 'dark' ? '#31313f' : '#EBEBEF',
      },
    },
  });
  const classes = useStyles();
  return (
    <WatchButton
      theme={theme}
      onClick={watchTutorial}
      className={`h-full ${classes.buttonOutlinedSm} ${className}`}
      id={id}
    >
      <img
        src={theme === 'dark' ? '/assets/video-dark.svg' : '/assets/video.svg'}
        alt='video'
        className='mr-2'
      />
      Watch tutorial
    </WatchButton>
  );
};

WatchTutorialButton.propTypes = {
  watchTutorial: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default WatchTutorialButton;
