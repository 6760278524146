import React, { useEffect, useState } from 'react';

/*
This is a custom hook that allows you to check if a user is visiting a certain feature for the first time.
To use it, import it and call it with an object that has a property featureName which is a string that represents the feature being checked.
It returns a boolean, isFirstTimeVisit which is true if it's the first time the user is visiting this feature and false otherwise.
The hook uses local storage to keep track of which features have been introduced to the user.

*Mostly used for showing a Tooltip, Modal, or Tutorial the first time a user visits a certain feature.
*/

const useFirstTimeVisitChecker = (featureName) => {
  const [isFirstTimeVisit, setIsFirstTimeVisit] = useState(false);

  useEffect(() => {
    const introducedFeatures =
      JSON.parse(window?.localStorage.getItem('introduced-features')) || {};

    setIsFirstTimeVisit(!introducedFeatures[featureName]);

    if (!introducedFeatures[featureName]) {
      introducedFeatures[featureName] = true;
      localStorage.setItem(
        'introduced-features',
        JSON.stringify(introducedFeatures)
      );
    }
  }, [featureName]);

  return isFirstTimeVisit;
};

export default useFirstTimeVisitChecker;
