import React from 'react';
import { Box } from '@mui/system';
import dynamic from 'next/dynamic';
import { posthog } from 'posthog-js';

const CustomButton = dynamic(() => import('@components/Button/Button'), {
  ssr: false,
});
export default function JoinCard() {
  const openFacebookGroup = (url) => {
    posthog.capture('Button: Open the Facebook Group');
    window.open(url, '__blank');
  };
  return (
    <div>
      <Box
        padding='13px 15px'
        className='bg-white rounded-md dark:bg-header-black dark:border-1 dark:border-custom-border'
        border='1px solid #EBEBEF'
      >
        <div>
          <Box>
            <div
              //  variant='body1'
              className='font-bold '
            >
              What’s new
            </div>
            <div className=' pt-3'>
              <div className='text-sm'>
                Get inspired by experts in generative AI and content performance
                to grow your business.
              </div>
            </div>
            <div className='pt-5'>
              <CustomButton
                id='button-open-facebook-group-ink'
                btnColor='blue'
                onClick={() =>
                  openFacebookGroup(
                    'https://www.facebook.com/groups/231841718402866'
                  )
                }
              >
                Open Facebook Group
              </CustomButton>
            </div>
          </Box>
        </div>
      </Box>
    </div>
  );
}
