import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const LoadingState = ({ size = 20, color = 'inherit' }) => (
  <Box sx={{ display: 'flex' }}>
    <CircularProgress size={size} color={color} />
  </Box>
);

LoadingState.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default LoadingState;
